import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useAuthContext } from '../hooks/useAuthContext';

const Orders = () => {

  const { user } = useAuthContext();
  const [ordersData, setOrdersData] = useState([]);

  const fetchOrdersData = async () => {
    const response = await fetch('https://sgevent.store/api/order/user/' + user.username.toLowerCase(), {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    console.log(json);
    setOrdersData(json);
  }

  useEffect(() => {

    if (user) {
      fetchOrdersData();
    }

	}, []);

  return (
    <>
      <div className="container form-page-padding">
        {/* Order History */}
        <div className="row mt-3 mb-5">
          <div className="col-12">
						<h3 className="mb-0">Order Records</h3>
            <button className="btn action-button mt-2" onClick={(e) => {
              // fetchOrdersData();
              window.location.reload();
            }}>
              <h6 className="text-gray mb-0"><i className="fa-solid fa-rotate-right mr-2"></i>Refresh Page</h6>
            </button>
					</div>
					<div className="col-12 mt-3">
            {ordersData && ordersData.length > 0 ? (
              <table className="table table-hover table-striped">
                <thead className="thead-dark">
                  <tr className="">
                    <th className="p-3" scope="col"><p className="mb-0">Order Details</p></th>
                  </tr>
                </thead>
                <tbody>
                {ordersData && ordersData.map((orders, index) => (
                  <tr key={index}>
                    <td className="px-3 py-2">
                      <p className="mb-0"><strong>Order</strong> #{ordersData[index].orderID}</p>
                      <p className="mb-0">
                        <strong>Order Type: </strong>
                        <span>
                          {ordersData[index].item1 && "•B2B "}
                          {ordersData[index].item2 && "•B2C "}
                          {ordersData[index].item3 && "•Single Piece "}
                          {ordersData[index].item4 && "•Multi Piece "}
                        </span>
                      </p>
                      <p className="mb-0">
                        {ordersData[index].status === "Matching" ? <strong>Order Status: <span className="vip-yellow">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Matched" ? <strong>Order Status: <span className="profit-green">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Cancelled" ? <strong>Order Status: <span className="error">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Error" ? <strong>Order Status: <span className="error">{ordersData[index].status}</span></strong> : null}
                      </p>
                      <p className="mb-0"><strong>Order Amount: </strong>{parseFloat(ordersData[index].price).toFixed(2)}</p>
                      <p className="mb-0"><strong>Total Order: </strong><span>{parseFloat(ordersData[index].totalAmount).toFixed(2)}</span></p>
                      {ordersData[index].commission > 0 ? (
                        <p className="mb-0"><strong>Commission: <span className="profit-green">{parseFloat(ordersData[index].commission).toFixed(2)}</span></strong></p>
                      ):(
                        <p className="mb-0"><strong>Commission: </strong>0.00</p>
                      )}
                      <p className="mb-0"><strong>Date & Time: </strong>{Moment(ordersData[index].createdAt).format('YYYY-MM-DD HH:mm')}</p>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            ):(
              <p className="">No order bidding history found.</p>
            )}
					</div>
        </div>
      </div>
    </>
  );

};

export default Orders;