import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from './context/authContext';
import { UserContextProvider } from './context/userContext';
import { PropertyContextProvider } from './context/propertyContext';
import "./assets/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";

ReactDOM.render(
  <AuthContextProvider>
    <UserContextProvider>
      <PropertyContextProvider>
        <App />
      </PropertyContextProvider>
    </UserContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")  
);