import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import Moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useOrder } from '../hooks/useOrder';

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);


const Property = () => {

  const { productID }  = useParams();

  const { user } = useAuthContext();

	const [propertyData, setPropertyData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [curOrderID, setCurOrderID] = useState(0);
  const [curOrderPeriod, setCurOrderPeriod] = useState();
  const [nextOrderCountdown, setNextOrderCountdown] = useState();

  const [amount, setAmount] = useState(0);

  const [roomType1, setRoomType1] = useState(false);
  const [roomType2, setRoomType2] = useState(false);
  const [roomType3, setRoomType3] = useState(false);
  const [roomType4, setRoomType4] = useState(false);

  const [roomMultiply1, setRoomMultiply1] = useState(1);
  const [roomMultiply2, setRoomMultiply2] = useState(1);
  const [roomMultiply3, setRoomMultiply3] = useState(1);
  const [roomMultiply4, setRoomMultiply4] = useState(1);

  const [modalDelay, setModalDelay] = useState(false);

  const { placeOrder, generateNextOrder, error, isLoading, orderSuccess } = useOrder();

  const fetchPropertyData = async () => {
    const response = await fetch('https://sgevent.store/api/product/' + productID, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    setPropertyData(json);
  }

  const getUserData = async () => {
    const response = await fetch('https://sgevent.store/api/user/'+ user.username.toLowerCase(), {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();

    if (response.ok) {
      if (json.length === 1) {
        setUserData(json[0]);
        if (json[0].freezed) {
          localStorage.removeItem('chabevent_logged_in');
          window.location.href= "/";
        }
      } else {
        console.log("Invalid user data: More than 1 result.")
      }
    }
  }

  const fetchOrderLogs = async () => {
    // const response = await fetch('https://sgevent.store/api/orderLog/');
    // const json = await response.json();
    setCurOrderID(parseFloat((Date.now()-1693778400000)/240000).toFixed(0));
    setCurOrderPeriod(Number(1693778400000)+Number(parseFloat((Date.now()-1693778400000)/240000).toFixed(0)*240000)+Number(120000));
    setNextOrderCountdown(Number(1693778400000)+Number(parseFloat((Date.now()-1693778400000)/240000).toFixed(0)*240000)+Number(180000));
    // if (Date.now() > (Moment(json[0].createdAt).unix()*1000)+240000) {
      // await generateNextOrder(110599);
    // }
    // parseFloat((Date.now()-1693778400000)/240000).toFixed(0);
  }

  const orderTimeLimit = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="col-12 px-5 text-center">
            <img className="ordering-product-icon" src={propertyData.coverImgLink} alt="Product Cover"></img>
            <h6 className="mt-3 mb-0">Cash Wallet:</h6>
            <div className="page-sub-container p-2">
              <h3 className="profit-green mb-0">{userData && userData.cashWallet > 0 ? parseFloat(userData.cashWallet).toFixed(2) : parseFloat(0).toFixed(2)}</h3>
            </div>
          </div>
          <div className="page-container text-center py-4 mt-3">
            <h6 className="error">Order #{curOrderID} expired.<br></br>Please wait for the next order...</h6>
            {nextOrderCountdown && nextOrderCountdown != null ? (
              <Countdown date={nextOrderCountdown} renderer={nextOrder}/>
            ):(
              <></>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="col-12 px-5 text-center">
            <img className="ordering-product-icon" src={propertyData.coverImgLink} alt="Product Cover"></img>
            <h6 className="mt-3 mb-2">Cash Wallet:</h6>
            <div className="page-sub-container p-2">
              <h3 className="profit-green mb-0">{userData && userData.cashWallet > 0 ? parseFloat(userData.cashWallet).toFixed(2) : parseFloat(0).toFixed(2)}</h3>
            </div>
            <h6 className="mt-3">Order ID: #{curOrderID}</h6>
            <h6 className="mt-3">Time Left: <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span></h6>
            <h6></h6>
          </div>

          <div className="row px-2">
              <div className="col-12 mt-3 px-2">
                <p className="mb-0"><strong>Please Select Order Slip (min. select 1):</strong></p>
              </div>
              <div className="col-6 col-sm-3 px-1 px-md-2 mt-2">
                <button className="btn w-100 option-button" onClick={(e) => {
                  setRoomType1(!roomType1);
                  if (roomType1) {
                    setRoomMultiply1(1);
                  } else {
                    setRoomMultiply1(2);
                  }
                }}>
                  <p className="mb-0" style={{fontSize: "12px"}}>B2B</p>
                  <input type="radio" checked={roomType1}></input>
                </button>
              </div>
              <div className="col-6 col-sm-3 px-1 px-md-2 mt-2">
                <button className="btn w-100 option-button" onClick={(e) => {
                  setRoomType2(!roomType2);
                  if (roomType2) {
                    setRoomMultiply2(1);
                  } else {
                    setRoomMultiply2(2);
                  }
                }}>
                  <p className="mb-0" style={{fontSize: "12px"}}>B2C</p>
                  <input type="radio" checked={roomType2}></input>
                </button>
              </div>
              <div className="col-6 col-sm-3 px-1 px-md-2 mt-2">
                <button className="btn w-100 option-button" onClick={(e) => {
                  setRoomType3(!roomType3);
                  if (roomType3) {
                    setRoomMultiply3(1);
                  } else {
                    setRoomMultiply3(2);
                  }
                }}>
                  <p className="mb-0" style={{fontSize: "12px"}}>SINGLE PIECE</p>
                  <input type="radio" checked={roomType3}></input>
                </button>
              </div>
              <div className="col-6 col-sm-3 px-1 px-md-2 mt-2">
                <button className="btn w-100 option-button" onClick={(e) => {
                  setRoomType4(!roomType4);
                  if (roomType4) {
                    setRoomMultiply4(1);
                  } else {
                    setRoomMultiply4(2);
                  }
                }}>
                  <p className="mb-0" style={{fontSize: "12px"}}>MULTI PIECE</p>
                  <input type="radio" checked={roomType4}></input>
                </button>
              </div>
            </div>
            <form className="w-100" onSubmit={handleOrder}>
              <div className="mb-2">
                <label htmlFor="amount">
                  <p className="mt-3 mb-0"><strong>Amount:</strong></p>
                </label>
                <input
                  type="text"
                  step="0.01"
                  placeholder='Enter Amount'
                  autoComplete='off'
                  name='amount'
                  className='form-control'
                  onChange={handleAmountInput}
                  onWheel={event => { event.preventDefault(); }}
                />
                {/* <p className={!balError ? "mt-0 mb-0" : "error mt-0 mb-0"}>Balance: {parseFloat(userData.cashWallet).toFixed(2)}</p> */}
                {balError && <div className="error-box mt-2">{balError}</div>}
              </div>
              <p className="mt-3 mb-0"><strong>Total Order Amount:</strong></p>
              <p className="mb-0">{parseFloat(amount*roomMultiply1*roomMultiply2*roomMultiply3*roomMultiply4).toFixed(2)}</p>
              <button disabled={isLoading || amount == 0 || invalidAmount || roomMultiply1*roomMultiply2*roomMultiply3*roomMultiply4 <= 1} type="submit" className="btn action-button mt-2 w-100" data-toggle="modal" data-target="#bookingConfirmationModal" onClick={(e) => {
		            setTimeout(() => setModalDelay(true), 500);
              }}>
                <h6 className="text-gray mb-0">Purchase</h6>
              </button>
            </form>
        </>
      );
    }
  };

  const nextOrder = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <button className="btn action-button" onClick={(e) => {
            window.location.reload();
          }}>
            <h6 className="text-gray mb-0"><i className="fa-solid fa-rotate-right mr-2"></i>Refresh Order</h6>
          </button>
        </>
      );
    } else {
      return (
        <>
          <h3><span>{zeroPad(minutes)}:{zeroPad(seconds)}</span></h3>
        </>
      );
    }
  };

  const handleOrder = async (e) => {
		e.preventDefault();
		await placeOrder(
      curOrderID,
      user.token,
      userData._id,
      userData.cashWallet,
      userData.username.toLowerCase(),
      userData.status,
      roomType1,
      roomType2,
      roomType3,
      roomType4,
      amount,
      amount*roomMultiply1*roomMultiply2*roomMultiply3*roomMultiply4
    );
    getUserData();
	};

  const [balError, setBalError] = useState('');
  const [invalidAmount, setInvalidAmount] = useState(true);
  const handleAmountInput = (e) => {
    const value = e.target.value;
    console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
    if (isNaN(+value)) {
      setInvalidAmount(true);
      setBalError('Input amount is not a number.');
    } else if (userData.cashWallet < e.target.value) {
      setInvalidAmount(true);
      setBalError('Insufficient fund. Please top up cash wallet.');
    } else {
      setInvalidAmount(false);
      setBalError('');
    }
    setAmount(e.target.value);
	};

  const [loadingScreen, setLoadingScreen] = useState(true);
  useEffect(() => {
    if (user) {
      fetchPropertyData();
      getUserData();
      setTimeout(() => fetchOrderLogs(), 1000);
      setTimeout(() => setLoadingScreen(false), 2000);
      // setLoadingScreen(false);
    }
	}, []);

  return (
    <>
      <div className="modal fade" id="bookingConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="bookingConfirmation" aria-hidden="true">
				<div className="modal-dialog modal-centered" role="document">
					<div className="modal-content">
            {modalDelay ? (
              <div className="modal-body">
                {orderSuccess ? (
                  <div className="text-center">
                    <span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
                    <h5 className="mb-0">Success</h5>
                    <span>
                      <p className="mb-0">{roomType1 && "•B2B "}</p>
                      <p className="mb-0">{roomType2 && "•B2C "}</p>
                      <p className="mb-0">{roomType3 && "•Single Piece "}</p>
                      <p className="mb-0">{roomType4 && "•Multi Piece "}</p>
                    </span>
                    <br></br>
                    <p className="mb-0"><strong>Amount: </strong>{parseFloat(amount).toFixed(2)}</p>
                    <p className="mb-0"><strong>Total Order: </strong>{parseFloat(amount*roomMultiply1*roomMultiply2*roomMultiply3*roomMultiply4).toFixed(2)}</p>
                    <br></br>
                    <button className="btn action-button mt-2" onClick={(e) => {
                      window.location.href = "/orders";
                    }}>
                      <p className="mb-0"><strong>View Orders</strong></p>
                    </button>
                  </div>
                ):(
                  <div className="text-center">
                    <span className="error-icon"><i className="fa-solid fa-circle-xmark"></i></span>
                    <h5 className="">Order Bid Failed</h5>
                    {error && <div className="error-box">{error}</div>}
                    <p className="mt-3">Please contact support or try again.</p>
                    <button className="btn action-button" onClick={(e) => {
                      window.location.reload();
                    }}>
                      <p className="mb-0"><strong>Reload</strong></p>
                    </button>
                  </div>
                )}
              </div>
            ):(
              <></>
            )}
					</div>
				</div>
			</div>
      
      {loadingScreen ? (
        <div className="container form-page-padding">
          <div className="row my-3">
            <div className="col-12 px-2 text-center">
              <p className="pb-0">Loading latest order... Please wait...</p>
            </div>
          </div>
        </div>
      ):(
        <div className="container form-page-padding">
          <div className="row my-3">
            <div className="col-12 px-2">
              {curOrderPeriod && curOrderPeriod != null ? (
                <Countdown date={curOrderPeriod} renderer={orderTimeLimit}/>
              ):(
                <></>
              )}
            </div>
          </div>
        </div>
      )}

    </>
  );

};

export default Property;