import { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

// hooks
import { useSignup } from '../hooks/useSignup';

import LogoText from '../assets/img/logo.png';

const Signup = () => {
	const [username, setUsername] = useState('');
	const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
	const {signup, error, isLoading} = useSignup();

	const handleSubmit = async (e) => {
        e.preventDefault();
		await signup(username, fullname, email, phone, password);
    };

  	return (
		<div className="full-page">
			<div className="container login-page form-page-padding">
				<div className="row form-bg rounded px-3 py-5 my-5">
					<div className="col-12">
						<img className="form-logo mb-3" src={LogoText} alt="logo"></img>
						<form className="w-100" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="username"><strong className="text-gray">Username</strong></label>
								<input
									type="text"
									name='username'
									placeholder='username'
									className='form-control text-lowercase'
									onChange={(e) => setUsername(e.target.value)}
								/>
								<h6 className="error mt-1">Please DO NOT use symbol for username - !@#$%^&*. not allowed</h6>
							</div>
							<div className="mb-2">
								<label htmlFor="fullname"><strong className="text-gray">Full Name</strong></label>
								<input
									type="text"
									name='fullname'
									placeholder='full name'
									className='form-control'
									onChange={(e) => setFullname(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="email"><strong className="text-gray">Email</strong></label>
								<input
									type="text"
									name='email'
									placeholder='email'
									className='form-control'
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="phone"><strong className="text-gray">Phone No.</strong></label>
								<PhoneInput
									inputProps={{
										name: 'phone',
										placeholder: 'phone no.',
										required: true
									}}
									inputClass="w-100"
									country={"my"}
									enableSearch={false}
									value={phone}
									onChange={(phone) => setPhone(phone)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="password"><strong className="text-gray">Password</strong></label>
								<input
									type="password"
									name='password'
									placeholder='password'
									className='form-control'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn action-button mt-3 w-100">
								<p className="mb-0"><strong>Sign Up</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
						<p className="text-gray mt-3 mb-0">Already have an account?</p>
						<Link to="/" className="btn secondary-btn w-100">
							<p className="mb-0"><strong>Login</strong></p>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);

};

export default Signup;