import { useAuthContext } from './useAuthContext';

export const useLogout = () => {
    const { dispatch } = useAuthContext();

    const logout = () => {
        // remove user from storage
        localStorage.removeItem('chabevent_logged_in');

        // dispatch login action
        dispatch({type: 'LOGOUT'});
    }

    return {logout};

}